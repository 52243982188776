<template>
	<div class="form">
		<h3 class="form__heading">Приглашаем вас в нашу школу</h3>
		<Input ref="nameInput" class="form__input" v-model="name" placeholder="Имя Фамилия" type="text"
			regexp="[A-ZА-Я][a-zа-я]+\s[a-zа-яA-ZА-Я\s\.]+" error="Неверный формат. Введите только буквы." />
		<Input ref="phoneInput" :id="'phone'" class="form__input" v-model="phone" type="tel"
			error="Неверный формат телефон номера." />
		<Input ref="emailInput" class="form__input" v-model="email" placeholder="E-mail" type="email" regexp=".+@.+\..+"
			error="Неверный формат почты" autocomplete="email" />
		<div class="form__subjects">
			<div v-for="subject in subjects.selected" :key="subject.id" class="form__subjects__element">
				<div class="form__subjects__element__content">
					{{ subject.name }}
				</div>
				<button @click="subjects.removeSelected(subject.id)" class="form__subjects__element__close" />
			</div>
		</div>
		<div class="form__checkbox">
			<Checkbox v-model="check" class="form__checkbox__element" />
			<p class="form__checkbox__element">
				Я согласен с условиями обработки персональных данных
			</p>
		</div>
		<Button :disabled="buttonBlocked" class="form__button" buttonStyle="1"
			@click="validateAndSend()">Записаться</Button>
		<Popup :active="active" @close="active = false"></Popup>
	</div>
</template>

<script>
import { ref, toRaw } from "vue";
import subjectsStore from "@/stores/subjects";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

import Input from "./Input.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import Popup from "./Popup.vue";
export default {
	setup() {
		const name = ref("");
		const phone = ref("");
		const email = ref("");
		const check = ref(false);
		const subjects = subjectsStore();
		const telInput = ref();
		const active = ref(false);
		return { name, phone, email, check, subjects, active, telInput };
	},
	data() {
		return {
			isMounted: false,
		};
	},
	components: {
		Input,
		Button,
		Checkbox,
		Popup,
	},
	methods: {
		isFormValid() {
			return (
				this.$refs.nameInput.valid &&
				!this.$refs.nameInput.empty &&
				this.$refs.phoneInput.valid &&
				!this.$refs.phoneInput.empty &&
				this.$refs.emailInput.valid &&
				!this.$refs.emailInput.empty &&
				this.check
			);
		},
		async validateAndSend() {
			if (this.isFormValid()) {
				const data = {
					name: this.name,
					email: this.email,
					number: this.phone,
					subjects: toRaw(this.subjects.selected).flatMap(({ name }) => name),
					message: "",
				};
				let myHeaders = new Headers();
				myHeaders.append("Content-Type", "application/json");

				let requestOptions = {
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify(data),
				};
				await fetch("https://b.r.niiazaliev.fvds.ru/proposals", requestOptions)
					.then((response) => response.json())
					.then((json) => {
						console.log(json);
						this.name =
							this.$refs.nameInput.inputData =
							this.$refs.phoneInput.inputData =
							this.$refs.emailInput.inputData =
							this.email =
							this.phone = '';
						this.subjects.$reset();
						this.$refs.nameInput.empty =
							this.$refs.phoneInput.empty =
							this.$refs.emailInput.empty = true;
						this.check = false;
						this.active = true;
					});
			}
		},
		onInput() {
			this.$refs.phoneInput.valid = this.telInput.isValidNumber();
		}
	},
	computed: {
		buttonBlocked() {
			if (!this.isMounted) {
				return true;
			} else {
				return !this.isFormValid();
			}
		}
	},
	mounted() {
		this.isMounted = true;
		const inputElement = document.querySelector('#phone');
		this.telInput = intlTelInput(inputElement, {
			utilsScript:
				"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
			preferredCountries: ["ru", "kz"],
		});
		document.querySelector('.iti').style.display = 'block';
		document.querySelector('.iti__country-list').classList.add('input');
		const dropDownMenu = document.querySelector('.iti__country-list');
		dropDownMenu.style.border = '0.5px solid rgba(68, 36, 183, 0.30))';
		dropDownMenu.style.borderRadius = "10px";
		inputElement.addEventListener('focusout', () => {
			const value = this.telInput.getNumber();
			this.phone = this.$refs.phoneInput.value = value;
			this.$refs.phoneInput.empty = value == '' ? true : false;
			this.$refs.phoneInput.valid = this.telInput.isValidNumber();
		});
	},
};
</script>

<style scoped>
.form {
	max-width: 449px;
	background: #ffffff;
	border: 1px solid rgba(68, 36, 183, 0.3);
	border-radius: 6px;
	padding: 30px 30px 30px 21px;
	box-sizing: border-box;
}

.form__heading {
	font-size: 18px;
	margin-bottom: 9px;
	text-align: center;
}

.form__input {
	margin-bottom: 2px;
}

.form__input:last-child {
	margin-bottom: 30px;
}

.form__subjects {
	margin-bottom: 10px;
}

.form__subjects__element {
	display: inline-flex;
	background: rgba(68, 36, 183, 0.1);
	border-radius: 20px;
	padding: 5px 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.form__subjects__element>* {
	flex-grow: 0;
	flex-shrink: 0;
}

.form__subjects__element__content {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	user-select: none;
}

.form__subjects__element__close {
	width: 12px;
	height: 12px;
	background-image: url("@/assets/subjects__close.svg");
	padding: 0;
	outline: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	margin: 2px 0 0 5px;
}

.form__button {
	width: 100%;
	margin-top: 16px !important;
}

.form__checkbox {
	display: flex;
	justify-content: space-between;
	align-items: top;
}

.form__checkbox__element:first-child {
	margin-right: 10px;
	flex-grow: 0;
	flex-shrink: 0;
}

.form__checkbox__element:last-child {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 130%;
	opacity: 0.4;
}
</style>
