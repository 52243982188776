import { defineStore } from "pinia";

export default defineStore('subjects', {
	state() {
		return {
			selected: []
		};
	},
	actions: {
		addSelected(id, name) {
			this.selected.push({ id: id, name: name });
		},
		removeSelected(e) {
			this.selected = this.selected.filter((s) => { return s.id != e; });
		}
	},
	getters: {
		isSubjectSelected(state) {
			return (subjectId) => state.selected.find((e) => e.id == subjectId) != undefined;
		}
	}
});