<template>
	<Popup :active="active" @close="closePopup">
		<div class="popup__wrapper">
			<div class="popup" @click.stop>
				<div class="popup__content__wrapper">
					<div class="popup__content__header">
						<button class="popup__content__header__close" @click="closePopup"></button>
					</div>
					<p class="popup__content__text">
						Спасибо, мы свяжемся с вами<br>в ближайшее время!
					</p>
					<div class="popup__content__button__wrapper">
						<Button buttonStyle="2" class="popup__content__button button" @click="closePopup">Окей</Button>
					</div>
				</div>
			</div>
		</div>
	</Popup>
</template>

<script>
import Popup from "../Popup.vue";
import subjectsStore from "@/stores/subjects";
import Button from "@/components/Button.vue";

export default {
	setup() {
		const subjects = subjectsStore();

		return { isSubjectSelected: subjects.isSubjectSelected, subjects };
	},
	components: {
		Popup,
		Button,
	},
	props: ["active", "content"],
	emits: ["close"],
	methods: {
		closePopup() {
			this.$emit("close");

		},
	},
};
</script>

<style scoped>
.popup {
	border-radius: 6px;
	border: 1px solid rgba(68, 36, 183, 0.3);
	background: #fefcff;

	max-width: 352px;
	width: 100%;
}

.popup__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.popup__content__wrapper {
	padding: 12px 12px 30px 12px;
}

.popup__content__header__close {
	display: block;
	width: 42px;
	height: 42px;
	background-image: url("@/assets/popup_close.svg");
	background-position: 0 210px;
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.popup__content__header__close:focus {
	background-position-y: 42px;
}

.popup__content__header__close:hover {
	background-position-y: 168px;
}

.popup__content__header__close:active {
	background-position-y: 126px;
}

.popup__content__header {
	display: flex;
	justify-content: flex-end;
}

.popup__content__text {
	text-align: center;
	margin-top: 10px;
	margin-bottom: 23px;
}

.popup__content__button__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.popup__content__button {
	width: 160px;
	padding: 16px 0px !important;
}
</style>
