<template>
  <a class="phone" href="tel:+79131105966">
    <div class="phone__icon">
      <CallIcon />
    </div>
    <div class="phone__content">+7 913 110-59-66</div>
  </a>
</template>

<script>
import CallIcon from "@/assets/CallIcon.vue";

export default {
  components: {
    CallIcon,
  },
};
</script>

<style scoped>
.phone {
  display: flex !important;
  text-decoration: none !important;
}

.phone__icon svg {
  fill: #17084d;
  margin-right: 3px;
}

.phone__content {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #17084d;
  margin: 3px;
}
</style>
