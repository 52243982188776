<template>
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <linearGradient id="hover" gradientTransform="rotate(97.42)">
      <stop offset="0.1%" stop-color="#fb7a0d" />
      <stop offset="115.11%" stop-color="#fb9c0d" />
    </linearGradient>
    <path
      d="M13.1033 13.9315C12.4267 13.3787 11.6162 13.5056 11.2272 13.8629L10.4202 14.5739C10.0102 14.9351 9.39245 14.7685 9.39245 14.7685C5.01859 12.7894 4.61117 7.93727 4.61117 7.93727C4.61117 7.93727 4.66562 7.2997 5.14441 7.03705L6.08777 6.52136C6.55712 6.27675 6.95329 5.55848 6.66477 4.73313C5.88671 2.56956 5.25089 1.75875 4.95816 1.1706C4.64022 0.609843 4.05177 0.392413 3.36125 0.611669L3.34757 0.609257C1.90187 1.12124 0.217248 2.25949 0.425017 3.77385C0.944269 5.42346 1.53865 10.4949 5.49861 14.9458C7.35767 17.0484 10.4691 19.4158 11.8501 20.0791L11.8602 20.1021C13.2103 20.8161 14.8574 19.6152 15.8315 18.4365L15.8333 18.4262C16.2748 17.8508 16.2712 17.2318 15.8586 16.7544C15.1466 15.7983 14.0146 14.6818 13.1033 13.9315Z"
    />
  </svg>
</template>