import { defineStore } from "pinia";

export default defineStore('popup_active', {
    state() {
        return {
            value: false,
            prevOverflow: -1
        };
    },
    actions: {
        set(v) {
			const appEl = document.getElementById("app");
			const lockPaddingValue = window.innerWidth - document.querySelector('#app').offsetWidth + 'px';
			document.querySelector(".main").style.paddingRight = lockPaddingValue;
            if (v) {
				this.prevOverflow = window.top.scrollY;
                document.body.classList.add('popupActive');
                appEl.style.position = "fixed";
                appEl.style.top = "-" + this.prevOverflow + "px";
                window.scrollTo(0, 0);
            } else {
                document.body.classList.remove('popupActive');
                appEl.style.position = "static";
                appEl.style.top = "0";
                window.scrollTo(0, this.prevOverflow);
            }
            this.value = v;
        }
    }
});