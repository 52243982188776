<template>
  <Popup :active="active" @close="$emit('close')">
    <div class="popup">
      <h2 class="popup__heading">Остались вопросы? Спросите у нас</h2>
      <div class="popup__form">
        <Input
          ref="nameInput"
          type="text"
          v-model="data.name"
          placeholder="Имя фамилия"
          regexp="[A-ZА-Я]{1}[a-zа-я]+ [A-ZА-Я]{1}[a-zа-я]+"
          error="Неверный формат. Введите только буквы."
        />
        <Input
          ref="phoneEmailInput"
          type="text"
          v-model="data.phoneEmail"
          placeholder="Номер телефона или E-mail"
          regexp="^(.+@.+\..+)|(^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+)$"
          error="Неверный формат."
        />
        <textarea
          class="popup__form__input"
          v-model="data.question"
          placeholder="Ваш вопрос"
        ></textarea>
        <div class="popup__form__check">
          <Checkbox class="popup__form__check__check" v-model="data.check" />
          <p class="popup__form__check__text">
            Я согласен с условиями обработки персональных данных
          </p>
        </div>
        <Button
          :disabled="buttonBlock"
          class="popup__form__button"
          @click="postRequest()"
          buttonStyle="2"
          >Задать вопрос</Button
        >
      </div>
    </div>
  </Popup>
  <SuccessPopup :active="success" @close="success = false"></SuccessPopup>
</template>

<script>
import { ref } from "vue";
import Popup from "./Popup.vue";
import Button from "@/components/Button.vue";
import Input from "../register/Input.vue";
import Checkbox from "@/components/Checkbox.vue";
import SuccessPopup from "../register/Popup.vue";

export default {
  setup() {
    const data = ref({
      name: "",
      phoneEmail: "",
      question: "",
      check: false,
    });
	const success = ref(false);

    return { data, success };
  },
  data() {
    return {
      isMount: false,
    };
  },
  components: {
    Popup,
    Button,
    Checkbox,
    Input,
	SuccessPopup
  },
  computed: {
    buttonBlock() {
      if (!this.isMount) return true;
      return !this.isValid();
    },
  },
  methods: {
    isValid() {
      return (
        this.$refs.nameInput.valid &&
        this.$refs.phoneEmailInput.valid &&
        !this.$refs.nameInput.empty &&
        !this.$refs.phoneEmailInput.empty &&
        this.data.check &&
        this.data.question.length != 0
      );
    },
    async postRequest() {
      if (this.isValid()) {
        const reqObj = {
          name: this.data.name,
          email: null,
          number: null,
          message: this.data.question,
          subjects: null,
        };
        if (this.isEmail(this.data.phoneEmail)) {
          reqObj.email = this.data.phoneEmail;
        } else {
          reqObj.number = this.data.phoneEmail;
        }

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(reqObj),
        };
        await fetch("https://b.r.niiazaliev.fvds.ru/proposals", requestOptions)
          .then(() => {
			this.data.check = false;
			this.data.name= '';
			this.data.phoneEmail = '',
			this.data.question = '';
			this.$emit('close');
			this.success = true;
          });
      }
    },
    isEmail(text) {
      const emailPattern = /^.+@.+\..+$/;
      if (emailPattern.test(text)) {
        return true;
      }
      return false;
    },
  },
  props: ["active"],
  emits: ["close"],
  mounted() {
    this.isMount = true;
  },
};
</script>

<style scoped>
.popup__heading {
  text-align: center;
}

.popup__form {
  position: relative;
  max-width: 449px;
  border: 1px solid rgba(68, 36, 183, 0.3);
  border-radius: 6px;
  box-sizing: border-box;
}

.popup__form__heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 20px;
  color: #17084d;
}

textarea {
  margin-bottom: 20px;
  display: block;
  background: #ffffff;
  border: 0.5px solid var(--unnamed, rgba(68, 36, 183, 0.3));
  border-radius: 10px;

  padding: 14px 20px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
}

textarea::placeholder,
textarea {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(48, 32, 61, 0.6);
}

textarea.popup__form__input {
  height: 148px;
}

.popup__form__input:focus-within {
  outline: 1px solid #17084d;
  outline-offset: 2px;
}

.popup__form__check__text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #17084d;
  opacity: 0.6;
}

.popup__form__check {
  display: flex;
  margin-bottom: 18px;
}

.popup__form__check__check {
  margin-right: 10px;
}

.popup__form__button {
  width: 100%;
}

@media screen and (min-width: 770px) {
  .popup__heading {
    margin: 38px 0;
  }

  .popup__form {
    margin-left: 50px;
    margin-bottom: 33px;
    padding: 30px 32px;
  }
}

@media screen and (max-width: 769px) {
  .popup__heading {
    margin: 40px 0 54px 0;
  }

  .popup {
    width: 100vw;
  }

  .popup__form {
    margin: 0 18px 40px 18px;
    padding: 30px 20px 20px 20px;
  }
}
</style>
